import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PersonelSelectService {
    private readonly API_URL = `${environment.backendurl}` + 'api/';

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    personelList(filter, url: string): Observable<any> {
        url = `${this.API_URL}${url}`;
        return this.httpClient.post<any>(url, filter);
    }

    getPersonelDetail(apasId) {
        const url = `${this.API_URL}organization/personel/detay/${apasId}/`;
        return this.httpClient.get<any>(url);

    }
}
