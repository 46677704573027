<div class="d-flex justify-content-end" *ngIf="zoomIn">
    <nb-icon class="linked-div" (click)="buyut()" style="font-size: xx-large; color: #c3c3c3"
      icon="maximize-outline">
    </nb-icon>
    <nb-icon class="linked-div" (click)="kucult()" style="font-size: xx-large; color: #c3c3c3"
      icon="minimize-outline">
    </nb-icon>
  </div>

  <div class="d-flex justify-content-center">
    <div class="sahne" (mousemove)="cevir($event)" (mouseup)="donmeDur()" style="float: left;">
      <div class="dis_kutu" (wheel)="mouseTeker($event)" style="
          transform: translateZ(-600px) rotateY(-20deg) rotateX(0deg) scale(1); margin-bottom: 32px;
        " (mousedown)="donmeyeBasla($event)">
        <div class="cati_tabani"></div>
        <div class="cati_sol"></div>
        <div class="cati_sol1"></div>
        <div class="cati_sag"></div>
        <div class="cati_sag1"></div>
        <div class="kutu" *ngFor="let kat of blok_bilgi?.katlar; let i=index;">
          <div *ngIf="kat.daireler.length == 1" [ngTemplateOutlet]="katta_1_daire"
            [ngTemplateOutletContext]="{daireler:kat.daireler, indx:i}"></div>
          <div *ngIf="kat.daireler.length == 2" [ngTemplateOutlet]="katta_2_daire"
            [ngTemplateOutletContext]="{daireler:kat.daireler, indx:i}"></div>
          <div *ngIf="kat.daireler.length == 3" [ngTemplateOutlet]="katta_3_daire"
            [ngTemplateOutletContext]="{daireler:kat.daireler, indx:i }"></div>
          <div *ngIf="kat.daireler.length == 4" [ngTemplateOutlet]="katta_4_daire"
            [ngTemplateOutletContext]="{daireler:kat.daireler, indx:i}"></div>
          <div *ngIf="kat.daireler.length == 5" [ngTemplateOutlet]="katta_5_daire"
            [ngTemplateOutletContext]="{daireler:kat.daireler, indx:i}"></div>
          <div *ngIf="kat.daireler.length == 6" [ngTemplateOutlet]="katta_6_daire"
            [ngTemplateOutletContext]="{daireler:kat.daireler, indx:i}"></div>

          <div class="sag_yuz"></div>
          <div class="sol_yuz"></div>
          <div class="taban"></div>
          <!-- <div style="padding:2px; position: absolute; transform: translateZ(150px);left:-30px;bottom:3px;border:2px solid #FF5722;
          border-radius: 50%; color:#FF5722; font-size: medium; font-weight: 400;">B{{i + 1}}</div> -->
        </div>
      </div>
    </div>
  </div>

  



<!--kat şablonları-->
<ng-template #katta_1_daire let-daireler="daireler" let-kat="indx">
    <div [class]="'tekDaire_ic_yazi daire_' + daireler[0]?.status">
      {{daireler[0].ic_yazi}}
    </div>
    <div class="on_yuz duvar linked-div" style="color: #000;" [ngStyle]="{'background-color':daireler[0].renk}"
      (click)="bilgiGetir(daireler[0])"> {{daireler[0].daire_no}}</div>
    <div class="insan_resmi_ikiDaire_ic_yazi_sol" *ngIf="daireler[0].img_link">
      <img src="{{daireler[0].img_link}}">
    </div>
    <div class="arka_yuz"></div>
  </ng-template>
  
  
  
  <ng-template #katta_2_daire let-daireler="daireler" let-kat="indx">
    <div class="ikiDaire_ara_duvar"></div>
    <div class="arka_yuz"></div>
    <div [class]="'ikiDaire_ic_yazi_sol daire_' + daireler[0]?.status">
      {{daireler[0].ic_yazi}}</div>
    <div class="insan_resmi_ikiDaire_ic_yazi_sol" *ngIf="daireler[0].img_link">
      <img src="{{daireler[0].img_link}}">
    </div>
    <div [class]="'ikiDaire_ic_yazi_sag daire_' + daireler[1]?.status">
      {{daireler[1].ic_yazi}}</div>
    <div class="insan_resmi_ikiDaire_ic_yazi_sag" *ngIf="daireler[1].img_link">
      <img src="{{daireler[1].img_link}}">
    </div>
    <div class="on_yuz d-flex" style="background: none">
      <div class="duvar linked-div" style="color: #000; flex: 1;" [ngStyle]="{'background-color':daireler[0].renk}"
        (click)="bilgiGetir(daireler[0])">
        {{daireler[0].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1;" [ngStyle]="{'background-color':daireler[1].renk}"
        (click)="bilgiGetir(daireler[1])">
        {{daireler[1].daire_no}}
      </div>
    </div>
  </ng-template>
  
  
  
  <ng-template #katta_3_daire let-daireler="daireler" let-kat="indx">
    <div class="arka_yuz duvar linked-div" style="color: #000;" [ngStyle]="{'background-color':daireler[2].renk }"
      (click)="bilgiGetir(daireler[2])">{{daireler[2].daire_no}}</div>
    <div class="ucDaire_ara_duvar"></div>
    <div class="ucDaire_ara_duvar_arka"></div>
    <div [class]="'ikiDaire_ic_yazi_sol daire_' + daireler[0]?.status">
      {{daireler[0].ic_yazi}}</div>
    <div class="insan_resmi_ikiDaire_ic_yazi_sol" *ngIf="daireler[0].img_link">
      <img src="{{daireler[0].img_link}}">
    </div>
    <div [class]="'ikiDaire_ic_yazi_sag daire_' + daireler[1]?.status">
      {{daireler[1].ic_yazi}}</div>
    <div class="insan_resmi_ikiDaire_ic_yazi_sag" *ngIf="daireler[2].img_link">
      <img src="{{daireler[1].img_link}}">
    </div>
    <div [class]="'ucDaire_arka_yazi daire_' + daireler[2]?.status">
      {{daireler[2].ic_yazi}}</div>
    <div class="on_yuz d-flex" style="background: none">
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[0].renk }"
        (click)="bilgiGetir(daireler[0])">
        {{daireler[0].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[1].renk }"
        (click)="bilgiGetir(daireler[1])">
        {{daireler[1].daire_no}}
      </div>
    </div>
  </ng-template>
  <ng-template #katta_4_daire let-daireler="daireler" let-kat="indx">
    <div class="arka_yuz d-flex" style="background: none">
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[2].renk }"
        (click)="bilgiGetir(daireler[2])">
        {{daireler[2].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[3].renk }"
        (click)="bilgiGetir(daireler[3])">
        {{daireler[3].daire_no}}
      </div>
    </div>
    <div class="dortDaire_ara_duvar"></div>
    <div class="ucDaire_ara_duvar_arka"></div>
    <div [class]="'ikiDaire_ic_yazi_sol daire_' + daireler[0]?.status">
      {{daireler[0].ic_yazi}}</div>
    <div class="insan_resmi_ikiDaire_ic_yazi_sol" *ngIf="daireler[0].img_link">
      <img src="{{daireler[0].img_link}}">
    </div>
    <div [class]="'ikiDaire_ic_yazi_sag daire_' + daireler[1]?.status">
      {{daireler[1].ic_yazi}}</div>
    <div class="insan_resmi_ikiDaire_ic_yazi_sag" *ngIf="daireler[1].img_link">
      <img src="{{daireler[1].img_link}}">
    </div>
    <div [class]="'dortDaire_arka_yazi_sol daire_' + daireler[2]?.status">
      {{daireler[2].ic_yazi}}</div>
    <div [class]="'dortDaire_arka_yazi_sag daire_' + daireler[3]?.status">
      {{daireler[3].ic_yazi}}</div>
    <div class="on_yuz d-flex" style="background: none">
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[0].renk }"
        (click)="bilgiGetir(daireler[0])">
        {{daireler[0].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[1].renk }"
        (click)="bilgiGetir(daireler[1])">
        {{daireler[1].daire_no}}
      </div>
    </div>
  </ng-template>
  <ng-template #katta_5_daire let-daireler="daireler" let-kat="indx">
    <div class="arka_yuz d-flex" style="background: none">
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[3].renk }"
        (click)="bilgiGetir(daireler[3])">
        {{daireler[3].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[4].renk }"
        (click)="bilgiGetir(daireler[4])">
        {{daireler[4].daire_no}}
      </div>
    </div>
    <div class="besDaire_ara_duvar_sol"></div>
    <div class="besDaire_ara_duvar_sag"></div>
    <div class="ucDaire_ara_duvar_arka"></div>
    <div class="besDaire_ara_duvar_arka"></div>
    <div [class]="'besDaire_ic_yazi_sol daire_' + daireler[0]?.status">
      {{daireler[0].ic_yazi}}</div>
    <div [class]="'besDaire_ic_yazi_orta daire_' + daireler[1]?.status">
      {{daireler[1].ic_yazi}}</div>
    <div [class]="'besDaire_ic_yazi_sag daire_' + daireler[2]?.status">
      {{daireler[2].ic_yazi}}</div>
    <div [class]="'dortDaire_arka_yazi_sol daire_' + daireler[3]?.status">
      {{daireler[3].ic_yazi}}</div>
    <div [class]="'dortDaire_arka_yazi_sag daire_' + daireler[4]?.status">
      {{daireler[4].ic_yazi}}</div>
    <div class="on_yuz d-flex" style="background: none">
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[0].renk }"
        (click)="bilgiGetir(daireler[0])">
        {{daireler[0].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[1].renk }"
        (click)="bilgiGetir(daireler[1])">
        {{daireler[1].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[2].renk }"
        (click)="bilgiGetir(daireler[2])">
        {{daireler[2].daire_no}}
      </div>
    </div>
  </ng-template>
  
  
  
  <ng-template #katta_6_daire let-daireler="daireler" let-kat="indx">
    <div class="arka_yuz d-flex" style="background: none">
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[3].renk }"
        (click)="bilgiGetir(daireler[3])">
        {{daireler[3].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[4].renk }"
        (click)="bilgiGetir(daireler[4])">
        {{daireler[4].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1; " [ngStyle]="{'background-color':daireler[5].renk }"
        (click)="bilgiGetir(daireler[5])">
        {{daireler[5].daire_no}}
      </div>
    </div>
    <div class="besDaire_ara_duvar_sol"></div>
    <div class="besDaire_ara_duvar_sag"></div>
    <div class="altiDaire_ara_duvar_sol"></div>
    <div class="altiDaire_ara_duvar_sag"></div>
    <div class="ucDaire_ara_duvar_arka"></div>
    <div [class]="'besDaire_ic_yazi_sol daire_' + daireler[0]?.status">{{daireler[0].ic_yazi}}</div>
    <div [class]="'besDaire_ic_yazi_orta daire_' + daireler[1]?.status">{{daireler[1].ic_yazi}}</div>
    <div [class]="'besDaire_ic_yazi_sag daire_' + daireler[2]?.status">{{daireler[2].ic_yazi}}</div>
    <div [class]="'altiDaire_arka_yazi_sol daire_' + daireler[3]?.status">{{daireler[3].ic_yazi}}</div>
    <div [class]="'altiDaire_arka_yazi_orta daire_'+ daireler[4]?.status">{{daireler[4].ic_yazi}}</div>
    <div [class]="'altiDaire_arka_yazi_sag daire_'+ daireler[5]?.status"> {{daireler[5].ic_yazi}}</div>
  
    <div class="on_yuz d-flex" style="background: none">
      <div class="duvar linked-div" style="color: #000; flex: 1;" [ngStyle]="{'background-color':daireler[0].renk }"
        (click)="bilgiGetir(daireler[0])">
        {{daireler[0].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1;" [ngStyle]="{'background-color':daireler[1].renk }"
        (click)="bilgiGetir(daireler[1])">
        {{daireler[1].daire_no}}
      </div>
      <div class="duvar linked-div" style="color: #000; flex: 1;" [ngStyle]="{'background-color':daireler[2].renk }"
        (click)="bilgiGetir(daireler[2])">
        {{daireler[2].daire_no}}
      </div>
    </div>
  </ng-template>