import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { CrmTabType } from './crm-main/crm-main.component';
import { Router } from '@angular/router';

@Injectable()
export class CrmService {
    private readonly API_URL = `${environment.backendurl}api/crm/`;
    private readonly API_URL_PROJE = `${this.API_URL}proje/`;
    private readonly API_URL_BLOK = `${this.API_URL}blok/`;
    private readonly API_URL_DAIRE = `${this.API_URL}daire/`;
    private readonly API_URL_SATIS = `${this.API_URL}satis/`;
    private readonly API_URL_TEKLIF = `${this.API_URL}teklif/`;
    private readonly API_URL_ODEME_PLANI = `${environment.backendurl}api/muhasebe/`;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
    ) { }

    navigate(tab: CrmTabType) {
        this.router.navigate(['pages/crm/main'], {
            state: {
                tab: tab,
            },
        });
    }

    addProje(formData: any): Observable<any[]> {
        const url: string = `${this.API_URL_PROJE}add/`;
        return this.httpClient.post<any[]>(url, formData);
    }

    getProjeList(formData): Observable<any[]> {
        const url: string = `${this.API_URL_PROJE}list/`;
        return this.httpClient.post<any[]>(url, formData);
    }

    updateProje(projeId, data) {
        const url: string = `${this.API_URL_PROJE}update/${projeId}/`;
        return this.httpClient.patch(url, data);
    }

    getProjeDetail(projeId: number): Observable<any[]> {
        const url: string = `${this.API_URL_PROJE}detail/${projeId}/`;
        return this.httpClient.get<any[]>(url);
    }

    getBlokDetail(blokId: number): Observable<any> {
        const url: string = `${this.API_URL_BLOK}detail/${blokId}/`;
        return this.httpClient.get<any>(url);
    }

    updateBlok(blokId, data) {
        const url: string = `${this.API_URL_BLOK}update/${blokId}/`;
        return this.httpClient.patch(url, data);
    }

    updateDaire(data) {
        const url: string = `${this.API_URL_DAIRE}update/`;
        return this.httpClient.patch(url, data);
    }

    updateDaireStatus(daireId: number, data) {
        const url: string = `${this.API_URL_DAIRE}status/update/${daireId}/`;
        return this.httpClient.patch(url, data);
    }

    deleteDaireler(daireIds: number[]) {
        const url: string = `${this.API_URL_DAIRE}delete/`;
        return this.httpClient.post(url, { daire_ids: daireIds });
    }

    // SATIŞLAR

    getSatisList(formData) {
        const url: string = `${this.API_URL_SATIS}list/`;
        return this.httpClient.post<any[]>(url, formData);
    }

    addSatis(formData,module) {
        const url: string = `${this.API_URL}${module}/add/`;
        return this.httpClient.post<any[]>(url, formData);
    }


    updateSatis(satisId, data) {
        const url: string = `${this.API_URL_SATIS}update/${satisId}/`;
        return this.httpClient.patch(url, data);
    }

    // Teklifler

    getTeklifList(formData) {
        const url: string = `${this.API_URL_TEKLIF}list/`;
        return this.httpClient.post<any[]>(url, formData);
    }

    addTeklif(formData) {
        const url: string = `${this.API_URL_TEKLIF}add/`;
        return this.httpClient.post<any[]>(url, formData);
    }
    updateTeklif(formData,teklifId) {
        const url: string = `${this.API_URL_TEKLIF}update/${teklifId}/`;
        return this.httpClient.patch<any[]>(url, formData);
    }


    addOdemePlani(formVal) {
        const url = `${this.API_URL_ODEME_PLANI}odemeplan/add/`;
        return this.httpClient.post(url, formVal);
    }

    getOdemePlani(formVal) {
        const url = `${this.API_URL_ODEME_PLANI}odemeplan/list/`;
        return this.httpClient.post(url, formVal);
    }

    deleteOdemePlani(instanceId) {
        const url = `${this.API_URL_ODEME_PLANI}odemeplan/delete/${instanceId}/`;
        return this.httpClient.delete(url);
    }

    getOdemePlaniDetail(instanceId) {
        const url = `${this.API_URL_ODEME_PLANI}odemeplan/detail/${instanceId}/`;
        return this.httpClient.get(url);
    }

    payOdemePlaniTaksit(instanceId, formVal) {
        const url = `${this.API_URL_ODEME_PLANI}odemeplan/pay/${instanceId}/`;
        return this.httpClient.patch(url, formVal);
    }

    getUnpaidOdemeTaksits(cariId: number) {
        const url = `${this.API_URL_ODEME_PLANI}odemeplan/unpaid/${cariId}/`;
        return this.httpClient.get(url);
    }
    
    odenmisOlarakIsaretle(instanceId: number) {
        const data = {instance_id: instanceId}
        const url = `${this.API_URL_ODEME_PLANI}odemeplan/odendiisratle/`;
        return this.httpClient.patch(url, data);
    }

    odenmediOlarakIsaretle(instanceId: number) {
        const data = {instance_id: instanceId}
        const url = `${this.API_URL_ODEME_PLANI}odemeplan/odenmediisaretle/`
        return this.httpClient.patch(url, data);
    }

    updateCariHareket(instanceId, data): Observable<any> {
        const url: string = `${this.API_URL_ODEME_PLANI}carihareketetupdate/${instanceId}/`;
        return this.httpClient.patch<any>(
            url,
            data,
        );
    }
    addCariHareket(data): Observable<any> {
        const url: string = `${this.API_URL_ODEME_PLANI}addcarihareket/`;
        return this.httpClient.post<any>(
            url,
            data,
        );
    }

}
