import { HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { SortType, ColumnMode } from '@swimlane/ngx-datatable';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari, tarih_format } from '../../../pages/constants';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { ApasFileService } from '../../../services/apasfile.service';
import { FilePreviewerComponent } from '../file-preview-component/file-previewer.component';
import { ApasFilesUploadComponent } from './apas-files-upload/apas-files-upload.component';

@Component({
  selector: 'apas-files',
  templateUrl: './apas-files.component.html',
  styleUrls: ['./apas-files.component.scss'],
  providers: [ApasFileService],
})
export class ApasFilesComponent implements OnInit, OnChanges {
  @Input() instance_id;
  @Input() file_key: string = 'file';
  @Input() instance_key: string;
  @Input() url: string;
  @Input() modal: boolean = false;
  @Input() ekleComponent: any = ApasFilesUploadComponent;
  filterForm: FormGroup;
  tarih_format = tarih_format;
  rows: any[] = [];
  isLoading: boolean = false;
  durdur: boolean = false;
  SortType = SortType;

  ColumnMode = ColumnMode;

  expanded: any = {};
  timeout: any;
  duration: number;
  temp = [];
  currentFile: File;

  get filterFormGroup(): FormGroup { return this.filterForm.get('filter') as FormGroup; }
  get instanceIdController(): FormControl { return this.filterForm.get('instance_id') as FormControl; }
  constructor(
    public fb: FormBuilder,
    public fileService: ApasFileService,
    @Optional() private ref: NbDialogRef<ApasFilesComponent>,
    public nbDialogService: NbDialogService,
  ) {
    this.initForm();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.instance_id) {
      
      this.instance_id = changes?.instance_id?.currentValue;
        this.filterForm.get('instance_id').setValue(this.instance_id);
      this.rows = [];
      setTimeout(() => {
        this.getFiles();
      }, 300);
    }
  }

  ngOnInit() {
  }

  initForm() {
    this.filterForm = this.fb.group({
      page: [0],
      count: [20],
      search: [],
      instance_id: [],
      filter: this.fb.group({
        is_active: [true],
      }),
    });
    if (this.instance_id) {
      this.filterForm.get('instance_id').setValue(this.instance_id);
    }
    this.filterForm.get('search').valueChanges.subscribe(val => this.filtre());
    this.filtre();
  }

  filtre() {
    this.isLoading = true;
    this.durdur = false;
    this.filterForm.get('page').setValue(0);
    this.rows = [];
    setTimeout(() => {
      this.getFiles();
    }, 350);
  }

  getFiles() {
    this.isLoading = true;
    const formVal = removeAllNull(this.filterForm.value);
    
    this.fileService.getFilesList(this.url, formVal).subscribe(resp => {
      resp.forEach(elm => {
        elm = this.clearData(elm);
        this.rows = addOrUpdate(this.rows, elm, 'identifier');
      });
      this.rows = [...this.rows];
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.durdur = true;

    });
  }
  goToNextPage() {
    if (!this.isLoading && !this.durdur) {
      this.isLoading = true;
      this.filterForm.get('page').setValue(Number(this.filterForm.get('page').value) + 1);
      setTimeout(() => {
        this.getFiles();
      }, 350);
    }
  }
  clearData(elm) {
    elm.created_time = convertDateWithTZ(elm, 'created_time');
    return elm;
  }
  search(event) {
    const len = event.target.value.length;
    const arama = event.target.value.toUpperCase();


    if (len > 2) {
      this.rows = [];
      this.filterForm.get('page').setValue(0, { emitEvent: false, onlySelf: true });
      this.filterForm.get('search').setValue(arama);
    } else if (len === 0) {
      this.filterForm.get('page').setValue(0, { emitEvent: false, onlySelf: true });
      this.filterForm.get('search').setValue(null);
    } else {
      return;
    }
    this.getFiles();
  }
  close() {
    this.ref.close();
  }

  downLoadFile(id: number, row?: any) {

    if (!row[this.file_key]) {
      this.fileService.getFileDetail(this.url, row.identifier).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              row['percentDone'] = 0;

              row['isDownloading'] = true;
              break;
            case HttpEventType.DownloadProgress:
              row['percentDone'] = Math.round(
                (100 * event.loaded) / event.total,
              );

              break;
            case HttpEventType.Response:
              row[this.file_key] = event.body;
              row['isDownloading'] = false;
              row[this.file_key] = event.body[this.file_key];
              row['_file'] = event.body[this.file_key];
              // downLoadFile(row[this.file_key], row.title);
                
              this.nbDialogService.open(FilePreviewerComponent,
                {
                  context: { item: row },
                  closeOnBackdropClick: false,
                });
              break;
          }
        },
        (err: HttpErrorResponse) => {
          
          if (err.error instanceof Error) {
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          }
        },
      );
    } else {
      const context = {
        item: row,
      };
      this.nbDialogService.open(FilePreviewerComponent, { context: { item: row }, closeOnBackdropClick: false });
    }
  }


  deleteFile(row) {
    Swal.fire(
      {
        ...responseKodlari.shureToDelete,
        ...{ text: `${row.title} dosyasını silmek istediğinize emin misiniz?` },
      } as SweetAlertOptions,
    ).then(({ value }) => {
      if (value) {
        this.fileService.deleteFile(this.url, row.identifier).subscribe(resp => {
          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
          this.rows = this.rows.filter(x => x.identifier !== row.identifier);
          this.rows = [...this.rows];
        });
      }
    });
  }

  newFile() {
    this.nbDialogService.open(this.ekleComponent, {
      context: {
        file_key: this.file_key,
        url: this.url,
        instance_id: this.instance_id,
        instance_key: this.instance_key,
      }, closeOnBackdropClick: false,
    }).onClose.subscribe(resp => {
      if (resp) {
        setTimeout(() => {
          this.filtre();
        }, 350);
      }
    });
  }
  upDateFile(row) {
    const context = {
      file_key: this.file_key,
      url: this.url,
      instance_id: this.instance_id,
      instance_key: this.instance_key,
      row: row,
    };
    this.nbDialogService.open(this.ekleComponent, { context: context }).onClose.subscribe(resp => {
      if (resp) {
        setTimeout(() => {
          this.getFiles();
        }, 350);
      }
    });
  }
}
