import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'base-list-template',
  templateUrl: './base-list-template.component.html',
  styleUrls: ['./base-list-template.component.scss']
})
export class BaseListTemplateComponent implements OnInit {
  @Input() headerTemplate: TemplateRef<any>;
  @Input() inBetween: TemplateRef<any>; 
  @Input() tableHeader: TemplateRef<any>; 
  @Input() tableBody: TemplateRef<any>; 
  @Input() isLoading: boolean; 
  constructor() { }

  ngOnInit(): void {
  }

}
