import { createAction, props } from '@ngrx/store';

export const loadData = createAction('[Load Müşteriler List]', props<{ filter?: any[] }>());
// tslint:disable-next-line: max-line-length
export const loadDataSuccess = createAction('[Load Success Müşteriler List]', props<{ data: any[], isEnd?: boolean }>());
export const loadDataFail = createAction('[Load Musteri Fail]', props<{ error: string, isEnd?: boolean }>());

export const CelearAllData = createAction('[CelearAllData Musteri]');

export const selectItem = createAction('[Selected Musteri]', props<{ selectedId: number }>());
export const getDetail = createAction('[Get Musteri Detail]', props<{selectedId: number}>());

export const addNewItem = createAction('[Add New Musteri]', props<{ data: any }>());
export const addNewItemSuccess = createAction('[Add New | Patch Musteri Success]', props<{ data: any }>());
export const addNewItemFail = createAction('[Add New | Patch Musteri Fail]', props<{ error: any }>());

export const patchItem = createAction('[Add Patch Musteri]', props<{ data: any, id: number }>());



// export const getTagList = createAction('[Get List Tags]');
// export const setTagList = createAction('[Set Tag List]', props<{data: any}>());
// export const tagListFail = createAction('[Tag List Fail]', props<{error: string}>());

// export const addGroup = createAction('[ADD MUSTERI GROUP]', props<{data: any}>());
// export const addGroupSuccess = createAction('[ADD MUSTERI GROUP]', props<{data: any}>());
// export const addGroupFail = createAction('[ADD MUSTERI GROUP]', props<{error: string}>());
