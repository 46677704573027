import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
	catchError,
	map,
	mergeMap,
	switchMap,
	switchMapTo,
} from "rxjs/operators";
import { CrmService } from "../../crm-service";
import { DaireStatus } from "../../crm_enums";
import * as projelerAction from "./projeler.actions";
@Injectable()
export class CrmProjelerEffects {
	loadData$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(projelerAction.loadData),
			mergeMap((props) =>
				this.crmService.getProjeList(props).pipe(
					map((data: any) => {
						return projelerAction.loadDataSuccess({
							data: data,
							isEnd: false,
						});
					}),
					catchError(() =>
						of(
							projelerAction.loadDataFail({
								error: "Projeler Fail",
								isEnd: false,
							}),
						),
					),
				),
			),
		);
	});

	addNewProje$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(projelerAction.addNewProje),
			mergeMap((props) =>
				this.crmService.addProje(props).pipe(
					map((data: any) => {
						return projelerAction.addNewProjeSuccess({
							data: data,
						});
					}),
					catchError(() =>
						of(
							projelerAction.addNewProjeFail({
								error: "Projeler Add Fail",
							}),
						),
					),
				),
			),
		);
	});

	getProjeDetail$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(projelerAction.getProjeDetail),
			mergeMap((props) =>
				this.crmService.getProjeDetail(props.projeId).pipe(
					map((data: any) => {
						data.bloklar.forEach((blok) => {
							blok = this.clearData(blok);
						});
						data.bloklar = data.bloklar.sort((x: any, y: any) =>
							x.blok_adi.localeCompare(y.blok_adi),
						);

						return projelerAction.setProjeDetail({
							projeId: props.projeId,
							data: data,
						});
					}),
					catchError(() =>
						of(
							projelerAction.addNewProjeFail({
								error: "Projeler Add Fail",
							}),
						),
					),
				),
			),
		);
	});

	clearData(blok) {
		blok.daireler.forEach((element) => {
			if (
				element.status === DaireStatus.SATILDI ||
				element.status === DaireStatus.REZERVE
			) {
				element["satis_fiyat_hide"] = true;
			} else {
				element["satis_fiyat_hide"] = false;
			}
		});
		return blok;
	}

	constructor(private actions$: Actions, private crmService: CrmService) {}
}
