import { ActionReducer, combineReducers, createFeatureSelector } from '@ngrx/store';
import { defaultMusteriState, MusterilerReducers, MusteriState } from './musteriler/musteri.reducer';
import { defaultProjelerState, ProjelerDefaultState, ProjelerReducers } from './projeler/projeler.reducer';
import { CrmSatisState, CrmSatisReducers, defaultCrmSatisState } from './satislar/satislar.reducer';
import { CrmTeklifReducers, CrmTeklifState, defaultCrmTeklifState } from './teklifler/teklif.reducer';

const crmReducer = {
        Projeler: ProjelerReducers,
        Musteriler: MusterilerReducers,
        Satislar: CrmSatisReducers,
        Teklifler: CrmTeklifReducers,
};


const defaultCrmStates = {
        Projeler: defaultProjelerState,
        Musteriler: defaultMusteriState,
        Satislar: defaultCrmSatisState,
        Teklifler: defaultCrmTeklifState,
};

interface CrmState {
        Projeler: ProjelerDefaultState;
        Musteriler: MusteriState;
        Satislar: CrmSatisState;
        Teklifler: CrmTeklifState;
}

const crmReducers: ActionReducer<CrmState> = combineReducers(crmReducer);

function CrmReducer(state: any, action: any) {
        return crmReducers(state, action);
}

const getCrmSelector = createFeatureSelector<CrmState>('CrmState');

export {
        getCrmSelector,
        CrmReducer,
        crmReducers,
        defaultCrmStates,
        CrmState,
};
