<nb-card size="giant" class="modalType animated fadeInRight" style="width: 40%;">
  <nb-card-header class="d-flex justify-content-between">
    <span>{{kullanici?.first_name}} {{kullanici?.last_name}} Yetkileri</span>

    <button nbButton (click)="close()" size="tiny" class="heryerdeClose">X</button>
  </nb-card-header>
  <nb-card-body>
    <div class="row" [formGroup]="form">
      <div class="col-md"  nbSpinnerSize="tiny">
        <h6>
          {{'YETKI.MODULLISTESI'|translate}}
        </h6>
        <nb-list formArrayName="perimissions">
          <nb-list-item  *ngFor=" let item of permissions?.controls;let index=index">
            <div class="col-md" [formGroupName]="index">
              {{item.get('modultitle').value}}
              <div style="float:right;">
                <nb-checkbox status="basic" class="ml-2" (change)="setPermission(index)" formControlName="IsUpdate">
                  {{ 'PERSONEL.DUZENLEYEBILIR' | translate }}</nb-checkbox>
              </div>
            </div>

          </nb-list-item>
        </nb-list>

      </div>
      

    </div>


  </nb-card-body>
  <nb-card-footer>
    <div class="col-md-12 text-center">
      <button status="primary" nbButton (click)="close()">Kaydet</button>
    </div>
  </nb-card-footer>
</nb-card>