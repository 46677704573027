import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { katNumberToName } from 'src/app/pages/constants';
import { convertDateWithTZ } from 'src/app/pages/global-functions';
import { CrmSatis } from '../../crm-model/projeler-model';
import { CrmService } from '../../crm-service';
import * as satislarActions from './satislar.actions';

@Injectable()
export class CrmSatislarEffects {
  @Effect()
  loadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(satislarActions.loadData),
      mergeMap((props) =>
        this.crmService.getSatisList(props).pipe(
          map((data: any) => {
            data.results = this.clearAllData(data.results);
            return satislarActions.loadDataSuccess({
              data: data,
              isEnd: false,
            });
          }),
          catchError(() =>
            of(
              satislarActions.loadDataFail({
                error: 'Satis Fail',
                isEnd: false,
              }),
            ),
          ),
        ),
      ),
    );
  });

  addNewSatis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(satislarActions.addNewSatis),
      mergeMap((props) =>
        this.crmService.addSatis(props.data,props.module).pipe(
          map((data: any) => {
            data = this.clearData(data);
            return satislarActions.addNewSatisSuccess({ data: data });
          }),
          catchError(() =>
            of(satislarActions.addNewSatisFail({ error: 'Satis Add Fail' })),
          ),
        ),
      ),
    );
  });

  getSatisDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(satislarActions.getSatisDetail),
      mergeMap((props) =>
        this.crmService.getProjeDetail(props.satisId).pipe(
          map((data: any) => {
            return satislarActions.setSatisDetail({
              satisId: props.satisId,
              data: data,
            });
          }),
          catchError(() =>
            of(satislarActions.addNewSatisFail({ error: 'Satis Add Fail' })),
          ),
        ),
      ),
    );
  });

  clearAllData(data: CrmSatis[]) {
    data.forEach((satis: CrmSatis) => {
      satis = this.clearData(satis);
    });

    return data;
  }
  clearData(item) {
    if (item.satis_tarihi)
      item.satis_tarihi = convertDateWithTZ(item, 'satis_tarihi');
    if (item.teklif_tarihi)
      item.teklif_tarihi = convertDateWithTZ(item, 'teklif_tarihi');
      item['kat_adi'] = katNumberToName(item.kat);
    // tslint:disable-next-line: max-line-length
    if (item.teklif_gecerlilik_tarihi)
      item.satis_tarihi = convertDateWithTZ(item, 'teklif_gecerlilik_tarihi');

      return item;
  }

  constructor(private actions$: Actions, private crmService: CrmService) {}
}
