<div [nbSpinner]="isLoading">
<!--Header Kısmı filter,Başlık vs gösterir-->
<nb-card class="ngBottom" *ngIf="headerTemplate" style="border:unset;">
        <ng-template *ngTemplateOutlet="headerTemplate"></ng-template>
</nb-card>
<!--Header Kısmı filter,Başlık vs gösterir-->


<!--Header ve Tablo Arasındaki boşluğu doldurmak istersen kullanabilirsin-->
<ng-container *ngIf="inBetween">
        <ng-template *ngTemplateOutlet="inBetween"></ng-template>
</ng-container>
<!--Header ve Tablo Arasındaki boşluğu doldurmak istersen kullanabilirsin-->


<!--Tablonun genel hattını oluşturan kısım-->
<nb-card class="anaTable" style="border:unset;">
        <nb-card-header *ngIf="tableHeader">
                <ng-template *ngTemplateOutlet="tableHeader"></ng-template>
        </nb-card-header>
        <nb-card-body *ngIf="tableBody">
                <ng-template *ngTemplateOutlet="tableBody"></ng-template>
        </nb-card-body>
</nb-card>
<!--Tablonun genel hattını oluşturan kısım-->
</div>