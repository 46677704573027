import { Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { colorSet } from 'src/app/pages/constants';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {

  @Input() formControl: FormControl;

  @Input() formControlName: string;

  colorSet = colorSet;
  colorBox = false;
  selectedColor;
  @Input() forceRefresh = 0;

  constructor(
    injector: Injector,
    private fb: FormBuilder,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forceRefresh) {
      if (this.control.value) {
        this.selectedColor = this.control.value;
      }
    }
  }


  ngOnInit(): void {
    this.selectedColorSet(this.control.value);
  }

  selectedColorSet(renk) {
    this.control.setValue(renk);
    this.colorBox = false;
    this.selectedColor = renk;
  }

}
