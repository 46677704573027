import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { unsubscribers } from 'src/app/pages/global-functions';

@Component({
  selector: 'ngx-base-detail',
  templateUrl: './base-detail.component.html',
  styleUrls: ['./base-detail.component.scss'],
})
export class BaseDetailComponent implements OnInit, OnDestroy {
  selectedItemConnector: any = null;
  detailData: any = null;
  detailComponent: any = null;
  editComponent: any = null;
  constructor(
    public injector: Injector,
    public store: Store<{}>,
    public ref: NbDialogRef<BaseDetailComponent>,
    public nbDialogService: NbDialogService,
  ) {}
  ngOnDestroy(): void {
    unsubscribers(this.subs);
  }

  ngOnInit(): void {
    this.subscribeItem();
  }

  subs = [];
  subscribeItem() {
    this.subs[0] = this.store
      .select(this.selectedItemConnector)
      .subscribe((data) => {
        console.log('Base Select Item : ', data);
        this.detailData = data;
      });
  }

  close() {
    this.ref.close();
  }
  editData() {
    this.nbDialogService
      .open(this.editComponent, { context: { edit: true } });
  }
}
