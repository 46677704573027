<div class="color-overflay" (click)="colorBox = !colorBox" *ngIf="colorBox"></div>
<div class="color-picker" (click)="colorBox = !colorBox" [style]="'background:'+selectedColor">
    <span *ngIf="!selectedColor">Renk Seç</span>
    <span *ngIf="selectedColor">{{selectedColor}}</span>
</div>

<div class="color-box" *ngIf="colorBox">
    <div class="color-list">
        <ul>
            <li *ngFor="let item of colorSet;" (click)="selectedColorSet(item)">
                <span class="p-3 d-block" [style.background]="item"></span>
            </li>
        </ul>
    </div>
</div>
<input type="text" style="display: none;" [formControl]="control" >
