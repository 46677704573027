import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { responseKodlari } from 'src/app/pages/constants';
import { removeAllNull, unsubscribers } from 'src/app/pages/global-functions';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'ngx-base-create',
  templateUrl: './base-create.component.html',
  styleUrls: ['./base-create.component.scss'],
})
export class BaseCreateComponent implements OnInit, OnDestroy {
  addNewAction: any = null;

  addLoadingConnector: any = null;
  addSuccessConnector: any = null;
  selectItemConnector: any = null;
  addFailConnector: any = null;

  form: FormGroup;
  isLoading = false;
  subscribers = [];
  edit = false;
  editData = null;


  constructor(
    public store: Store,
    public ref: NbDialogRef<BaseCreateComponent>
  ) { }
  ngOnDestroy(): void {
    unsubscribers(this.subscribers);
  }

  ngOnInit(): void {
    this.subscribers[0] = this.store.select(this.addLoadingConnector).subscribe((loading: any) => {
      this.isLoading = loading;
    });


    if (this.edit && this.selectItemConnector) {
      this.subscribers[1] = this.store.select(this.selectItemConnector).subscribe(data => {
        this.editData = data;
      });
    }

    // if (this.addFailConnector) {
    //   this.subscribers[4] = this.store.select(this.addFailConnector).subscribe(data => {
    //     Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    //   });
    // }

  }

  postForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.log('FORM : ', this.form);
      Swal.fire(responseKodlari.unValidForm as SweetAlertOptions);
      return;
    }
    const formVal = removeAllNull(this.form.value);
    this.store.dispatch(this.addNewAction(formVal));
    this.subscribers[2] = this.store.select(this.addSuccessConnector).subscribe((success: any) => {
      if (success) {
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
        this.closed();
        return;
      }
    });

  }

  patchForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.log('FORM : ', this.form);
      Swal.fire(responseKodlari.unValidForm as SweetAlertOptions);
      return;
    }

    const formVal = removeAllNull(this.form.value);
    this.store.dispatch(this.addNewAction(formVal));
    this.subscribers[3] = this.store.select(this.addSuccessConnector).subscribe((success: any) => {
      if (success) {
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);

        this.closed();
        return;
      }
      if (success === false) {
        Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
      }
    });

  }


  closed() {
    this.ref.close();
  }


}
