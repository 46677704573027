

<span [ngClass]="colorClass" *ngIf="!hide">
        <span class="linked-div" *ngIf="showHideOption && !hide">
                <nb-icon icon="eye-off-outline" class="hide_show_satis" (click)="hideItem()"></nb-icon>
        </span>

        <span [ngClass]="colorClass" *ngIf="animate" [@fadeInOut]="animateColor">
                {{ekranValue}} {{addToEnd ? addToEnd : ''}}
        </span>
        <span [ngClass]="colorClass" *ngIf="!animate">
                {{ekranValue}} {{addToEnd ? addToEnd : ''}}
        </span>
</span>

<span *ngIf="hide">
        <span class="linked-div" *ngIf="showHideOption && hide">
                <nb-icon icon="eye-outline" class="hide_show_satis" (click)="showItem()"></nb-icon>
        </span>
        <span>
                ********
        </span>
</span>