import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MusteriItem, MusteriGroupItem } from '../../crm-model/musteri-model';
import * as musteriActions from './musteriler.actions';


interface MusteriState extends EntityState<MusteriItem> {
    selectedItemId: number | null;
    end: boolean;
    addLoading: boolean;
    addSuccess: boolean;
    loading: boolean;
    loaded: boolean;
    error: String;
}


const defaultMusteriState: MusteriState = {
    ids: [],
    entities: {},
    selectedItemId: null,
    end: false,
    addLoading: false,
    addSuccess: false,
    loading: false,
    loaded: false,
    error: '',
};


function sortByName(a: MusteriItem, b: MusteriItem): number {
    return b.created_time - a.created_time;
}


const musterilerAdapter: EntityAdapter<MusteriItem> = createEntityAdapter<MusteriItem>({
    sortComparer: sortByName,
});


const initialState = musterilerAdapter.getInitialState(defaultMusteriState);


const musteriReducer = createReducer(
    initialState,
    on(musteriActions.loadData, (state, _props) => {
        return { ...state, loading: true };
    }),
    on(musteriActions.loadDataSuccess, (state, props: any) => {
        return musterilerAdapter.upsertMany(props.data.results, {
            ...state,
            loading: false,
            loaded: true,
        });
    }),
    on(musteriActions.loadDataFail, (state, _props) => {
        return { ...state, error: _props.error, loading: false, end: true };
    }),
    on(musteriActions.CelearAllData, () => {
        return { ...defaultMusteriState, ...{ end: false } };
    }),
    on(musteriActions.selectItem, (state, props) => {
        return { ...state, selectedItemId: props.selectedId };
    }),
    on(musteriActions.addNewItem, (state) => {
        return { ...state, addLoading: true };
    }),

    on(musteriActions.patchItem, (state) => {
        return { ...state, addLoading: true };
    }),

    on(musteriActions.addNewItemSuccess, (state, props: any) => {
        return {
            ...state,
            ...musterilerAdapter.upsertOne(props.data, state),
            addLoading: false,
            addSuccess: true,
            selectedItemId: props.data.id,
        };
    }),
    on(musteriActions.addNewItemFail, (state, props) => {
        return { ...state, error: props.error, addLoading: false, addSuccess: false };
    }),
);


function MusterilerReducers(action, state) {
    return musteriReducer(action, state);
}













// interface MusteriGroupState extends EntityState<MusteriGroupItem> {
//     selectedItemId: number | null;
//     end: boolean;
//     addLoading: boolean;
//     addSuccess: boolean;
//     loading: boolean;
//     loaded: boolean;
//     error: String;
// }


// const defaultMusteriGroupState: MusteriGroupState = {
//     ids: [],
//     entities: {},
//     selectedItemId: null,
//     end: false,
//     addLoading: false,
//     addSuccess: false,
//     loading: false,
//     loaded: false,
//     error: '',
// };


// const musteriGroupAdapter: EntityAdapter<MusteriGroupItem> = createEntityAdapter<MusteriGroupItem>({});


// const initialMusteriGroupState = musteriGroupAdapter.getInitialState(defaultMusteriGroupState);


// const musteriGroupReducer = createReducer(
//     initialMusteriGroupState,

//     on(musteriActions.setTagList, (state, props) => {
//         return musteriGroupAdapter.upsertMany(props.data, {
//             ...state,
//             loading: false,
//             loaded: true,
//         });

//     }),

//     on(musteriActions.addGroupSuccess, (state, props) => {
//         return musteriGroupAdapter.upsertOne(props.data, {
//             ...state,
//             loading: false,
//             loaded: true,
//         });
//     }),

//     on(musteriActions.tagListFail, (state, props) => {
//         return { ...state, ...{ tagError: props.error } };
//     }),
// );


// function MusteriGroupReducers(action, state) {
//     return musteriGroupReducer(action, state);
// }



export {
    MusteriState,
    defaultMusteriState,
    MusterilerReducers,
    musterilerAdapter,

    // MusteriGroupState,
    // defaultMusteriGroupState,
    // MusteriGroupReducers,
    // musteriGroupAdapter,
};








