import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
} from "@angular/core";

@Component({
	selector: "app-block-uc-boyutlu",
	templateUrl: "./block-uc-boyutlu.component.html",
	styleUrls: ["./block-uc-boyutlu.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockUcBoyutluComponent implements OnInit {
	@Input() blok_bilgi;
	@Input() zoomIn = true;
	@Output() change: EventEmitter<any> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	donme_izin = false;
	ilkX = 0;
	ilkY = 0;
	ilkDurumX: any = 0;
	ilkDurumY: any = 0;
	scaleFactor = 1;

	donmeDur() {
		this.donme_izin = false;
	}

	mouseTeker(evnt) {
		evnt.preventDefault();
		if (evnt.deltaY < 0) this.buyut();
		else this.kucult();
	}

	buyut() {
		const elm = document.querySelector(".dis_kutu");

		if (elm instanceof HTMLDivElement) {
			const ilktrnsfrm = elm.style.transform;
			const geciciScaleFactor = this.scaleFactor;
			this.scaleFactor += 0.1;
			if (this.scaleFactor > 1.5) this.scaleFactor = 1.5;
			const scaleFactor2 = this.scaleFactor.toFixed(1);
			const sontrnsfrm = ilktrnsfrm.replace(
				"scale(" + String(geciciScaleFactor) + ")",
				"scale(" + scaleFactor2 + ")",
			);
			elm.style.transform = sontrnsfrm;
			this.scaleFactor = Number(scaleFactor2);
		}
	}

	kucult() {
		const elm = document.querySelector(".dis_kutu");

		if (elm instanceof HTMLDivElement) {
			const ilktrnsfrm = elm.style.transform;
			const geciciScaleFactor = this.scaleFactor;
			this.scaleFactor -= 0.1;
			if (this.scaleFactor < 0.5) this.scaleFactor = 0.5;
			const scaleFactor2 = this.scaleFactor.toFixed(1);
			const sontrnsfrm = ilktrnsfrm.replace(
				"scale(" + String(geciciScaleFactor) + ")",
				"scale(" + scaleFactor2 + ")",
			);
			elm.style.transform = sontrnsfrm;
			this.scaleFactor = Number(scaleFactor2);
		}
	}
	donmeyeBasla(evnt) {
		this.ilkX = evnt.pageX;
		this.ilkY = evnt.pageY;
		const elm = document.querySelector(".dis_kutu");
		if (elm instanceof HTMLDivElement) {
			const ilkDurum = elm.style.transform;

			this.ilkDurumY = Number(ilkDurum.indexOf("rotateY("));
			const ilkDurumYdeg = ilkDurum.indexOf("deg");
			let yaziFark = Number(ilkDurumYdeg - (this.ilkDurumY + 10));
			this.ilkDurumY = Number(
				ilkDurum.substr(this.ilkDurumY + 10, yaziFark),
			);

			this.ilkDurumX = Number(ilkDurum.indexOf("rotateX("));
			const ilkDurumXdeg = ilkDurum.lastIndexOf("deg");
			yaziFark = Number(ilkDurumXdeg - (this.ilkDurumX + 10));
			this.ilkDurumX = Number(
				ilkDurum.substr(this.ilkDurumX + 10, yaziFark),
			);
		}

		this.donme_izin = true;
	}

	cevir(event?) {
		if (!this.donme_izin) return; // -20 101 100

		const simdiX = event.pageX;
		const simdiY = event.pageY;

		const farkX = this.ilkDurumY + simdiX - this.ilkX;

		const farkY = this.ilkDurumX + simdiY - this.ilkY;
		const elm = document.querySelector(".dis_kutu");
		// if (farkY > 5) farkY = 5;
		// if (farkY < -5) farkY = -5;

		if (elm instanceof HTMLDivElement) {
			// tslint:disable-next-line: max-line-length
			elm.style.transform =
				"translateZ(-600px) rotateY(" +
				farkX +
				"deg) rotateX(" +
				0 +
				"deg) scale(" +
				this.scaleFactor +
				")";
		}
	}

	bilgiGetir(daire) {
		this.change.emit(daire);
	}
}
