import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, Optional, OnDestroy, ChangeDetectionStrategy,
} from '@angular/core';
import { CalendarView } from 'angular-calendar';
import '../../../../../node_modules/angular-calendar/css/angular-calendar.css';

import { Router } from '@angular/router';
import { YetkiService } from '../../../services/yetki.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { YetkiComponent } from '../yetki/yetki.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { telefonKodu, tarih_format, prefixBase64, responseKodlari } from '../../../pages/constants';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import * as moment from 'moment';
import { PuantajModel } from '../../../models/puantaj_model';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ProfilPhotoComponent, profilType } from '../../../profil/kullanici-profil-main/profil-photo/profil-photo.component';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { ApasFilesComponent } from '../../apas-components/apas-files/apas-files.component';

@Component({
  selector: 'personel-detay',
  templateUrl: './personel-detay.component.html',
  styleUrls: ['./personel-detay.component.css'],
 changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [YetkiService, OrganizasyonService],
})
export class PersonelDetayComponent implements OnInit, OnDestroy {

  @ViewChild('yetkiComponent') yetkiComponent!: YetkiComponent;

  @Input() locale: string = 'en';

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
  isAdmin: boolean = false;
  kullaniciBilgileri;
  view: CalendarView = CalendarView.Month;
  iletisimEdit: boolean = false;
  viewDate: Date = new Date();
  telefon_kodu = telefonKodu;
  telefonKodu = telefonKodu[0].maskList;
  ulkem: FormControl = new FormControl(telefonKodu[0]);

  form: FormGroup;
  islemYapildi;
  //#region Puantaj variables
  puantaj: PuantajModel[] = [];
  isLoadingPuantaj: boolean = false;
  tarih_format = tarih_format;
  ColumnMode = ColumnMode;
  timeRangeFormControl: FormControl = new FormControl();

  startOfMoth: number;
  endOfMoth: number;
  canEdit: boolean = true;
  //#endregion

  prefixBase64 = prefixBase64;
  personelEdit = false;
  amIOwner: boolean = false;
  constructor(private router: Router,
    private yetkiService: YetkiService,
    private organizationService: OrganizasyonService,
    private fb: FormBuilder,
    private nbDialogService: NbDialogService,
    protected cd: ChangeDetectorRef,
    @Optional() private ref: NbDialogRef<PersonelDetayComponent>,
  ) {
    this.timeRangeFormControl.valueChanges.subscribe(data => {
      this.startOfMoth = Number(moment(data.start).format('X'));
      this.endOfMoth = Number(moment(data.end).format('X'));
     
    });
    this.amIOwner = JSON.parse(localStorage.getItem('org')).is_owner;
    // toDay
    const myDate = moment();
    this.startOfMoth = Number(myDate.startOf('month').format('X'));
    this.endOfMoth = Number(myDate.endOf('month').format('X'));
    this.initForm();
  }

  ngOnInit() {
    this.getPersonelProfile();
    this.orgSubscription();
  }
  organizationSubscription;
  hasAuthorization = false;
  orgSubscription() {
    this.organizationSubscription = this.organizationService.org$.subscribe(StateOrgInfo => {
      if (StateOrgInfo.moduls) {
        const modules = StateOrgInfo.moduls;
        const yetki = modules.find(x => x.slug === 'yetki');
        if (yetki.value) {
          this.hasAuthorization = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription) this.organizationSubscription.unsubscribe();
  }

  getPersonelProfile() {
    this.organizationService.getPersonelProfile(this.kullaniciBilgileri?.id).subscribe(resp => {
      this.kullaniciBilgileri = this.clearData(resp);
      this.setFormData();
    });
  }

  monthToYear(days: number) {
    const month = Math.floor(days / 30.5);
    const year = Math.floor(month / 12);
    const rem = Math.floor(month - (year * 12));
    const ayText = rem ? `${rem} ay` : '';
    const yearText = year ? `${year} yıl` : '';
    return `${yearText} ${ayText}`;
  }

  clearData(data) {
    if (data.leavedays_set.length > 0) {
      data.leavedays_set.forEach(element => {
        element.start_date = convertDateWithTZ(element, 'start_date');
        element.finish_date = convertDateWithTZ(element, 'finish_date');
      });
    }

    if (data.workareas_set.length > 0) {
      data.workareas_set.forEach(element => {
        element.start_date = convertDateWithTZ(element, 'start_date');
        element.finish_date = convertDateWithTZ(element, 'finish_date');
        element['edit'] = false;
      });
    }
    data.start_date = convertDateWithTZ(data, 'start_date'); // İşe Başlama Tarihi
    data.finish_date = convertDateWithTZ(data, 'finish_date'); // İşten Ayrılma Tarihi
    // tslint:disable-next-line: max-line-length
    data.calisma_suresi = this.monthToYear(moment(data.finish_date ? data.finish_date : new Date()).diff(moment(data.start_date), 'days'));
    return data;
  }
  setFormData() {
    this.form.patchValue(this.kullaniciBilgileri);
    this.form.get('job_id').setValue(this.kullaniciBilgileri?.job_id?.id);
    if (this.kullaniciBilgileri.is_user) {
      this.canEdit = false;
      this.form.disable();
    }
  }

  close() {
    this.ref.close(this.islemYapildi);
  }

  initForm() {
    this.form = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      job_id: [],
      unvan: [],
      kimlik_no: [null, Validators.required],
      phone: [null, Validators.required],
    });
  }


  giveAll() {
    this.yetkiService.giveAll(this.kullaniciBilgileri.apas_id).subscribe(data => {
      this.getDetail();
      Swal.fire({
        icon: 'success', title: `${this.kullaniciBilgileri.name} kişisine tüm yetkieri verdiniz.`
        , showConfirmButton: false, timer: 1000,
      })
        .then(() => {
          this.yetkiComponent.refreshYetki();
        });
    }, () => {
      Swal.fire({ icon: 'error', title: 'Bir şeyler yanlış gitti!', showConfirmButton: false, timer: 1500 });
    });
  }


  deactivatePersonel() {
    Swal.fire({
      title: 'Dikkat!',
      html: `${this.kullaniciBilgileri.name} kullanıcısını pasif yapmak üzeresiniz. Devam etmek istiyor musunuz?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',

    }).then(accept => {
      if (accept.value) {
        this.organizationService.deactivate_personel(this.kullaniciBilgileri.apas_id).subscribe(data => {
          this.kullaniciBilgileri.is_active = data.is_active;
          this.getDetail();
        });
      }
    });
  }
  isLoading: boolean = false;
  yetkiliKullanici(item) {

    let kalan_kullanim = 0;
    this.isLoading = true;

    this.organizationService.get_personel_remaining().subscribe(data => {
      kalan_kullanim = data['kalan'];
      this.isLoading = false;
      this.getDetail();
      this.islemYapildi = true;
      if (kalan_kullanim > 0) {

        Swal.fire({
          ...responseKodlari.userCount, html: `<b style='color:#01579b'>
      Mevcut Paketinizde Kalan Kullanıcı Hakkınız: <b  style='color:#dd3333'>${kalan_kullanim} </b></b><br> <b>
        ${item.name} </b>
        kullanıcı olarak artık veri girişi yapıp yetki verdiğiniz modulleri kullanabilecektir.`,
        } as SweetAlertOptions).then((result) => {
          if (result.value) {
            this.add_as_yetkili(item);
          }
        });
      } else {
        Swal.fire(responseKodlari.userAccessEnd as SweetAlertOptions).then((result) => {
          if (result.value) {
            this.router.navigate(['profil/firma-profil']);
          }
        });
      }
    }, () => {
      this.isLoading = false;
    });

  }

  add_as_yetkili(item) {

    this.isLoading = true;

    this.organizationService.add_yetkili_user({ 'apas_id': item.apas_id }).subscribe(data => {
      this.getDetail();
      if (data.SC01) {
        item.is_my_user = true;
        this.islemYapildi = true;
      } else if (data.SC02) {
        Swal.fire(responseKodlari.personel.personelDavetEdildi as SweetAlertOptions);
        this.islemYapildi = true;
      }
    }, () => {
      this.isLoading = false;

    });

  }


  removeUserAuth(kullanici) {
    Swal.fire({
      title: 'Onaylıyormusunuz ?',
      text: `${kullanici.name} kullanıcınızın yetkilerini alırsanız bu kullanıcı veri girişi yapamaz.`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'İptal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet',
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.organizationService.remove_yetkili_user(kullanici.apas_id).subscribe(success => {

          Swal.fire(
            'Kullanıcının yetkileri Kapatıldı.',
            ' ',
            'success',
          );
          this.getDetail();
          this.islemYapildi = true;

        });
      }
    });
  }

  reactiveatePersonel() {
    this.isLoading = true;
    this.organizationService.reactivate_personel(this.kullaniciBilgileri.apas_id).subscribe(data => {
      this.getDetail();
      this.islemYapildi = true;

    });
  }



  profilPhotoModal() {
    const context: {} = {
      'apasId': this.kullaniciBilgileri.apas_id,
      'type': profilType.Personel,
      'image': this.kullaniciBilgileri?.image,
    };
    this.nbDialogService.open(ProfilPhotoComponent, { context }).onClose.subscribe(() => {
      this.islemYapildi = true;
    });
  }

  updatePersonel() {
    this.isLoading = true;
    const formData = removeAllNull(this.form.value);
    this.organizationService.updatePersonelInformation(formData, this.kullaniciBilgileri.apas_id)
      .subscribe(response => {
        this.getDetail();
        this.islemYapildi = true;
        this.personelEdit = false;
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      }, err => {
        this.isLoading = false;
      });

  }


  getDetail() {
    this.isLoading = true;
    this.organizationService.getPersonelDetail(this.kullaniciBilgileri.apas_id).subscribe(resp => {
      this.kullaniciBilgileri = resp;
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

 
  openYetki() {
    this.nbDialogService.open(
      YetkiComponent,
      { context: { kullaniciBilgileri: this.kullaniciBilgileri }, closeOnBackdropClick: false },
    );
  }
  openPersonelFile() {
    const context = {
      instance_id: this.kullaniciBilgileri.apas_id,
      instance_key: 'apas_id',
      url: 'organization/personel',
      modal: true,
    };
    this.nbDialogService.open(ApasFilesComponent, { context: context, closeOnBackdropClick: false });
  }
}
