export enum DaireTip {
    KONUT = 'konut',
    DUKKAN = 'dukkan',
    OFIS = 'ofis',
}

export const DaireTipChoices = [
    { value: DaireTip.KONUT, label: 'Konut' },
    { value: DaireTip.OFIS, label: 'Ofis' },
    { value: DaireTip.DUKKAN, label: 'Dükkan' },
];

export type DaireStatusTypes = 'satilik' |  'satildi' | 'rezerve';

export enum DaireStatus {
    SATILIK = 'satilik',
    SATILDI = 'satildi',
    REZERVE = 'rezerve',
}

export const DaireStatusChoices = [

    { value: DaireStatus.SATILIK, label: 'Satılık' },
    { value: DaireStatus.SATILDI, label: 'Satıldı' },
    { value: DaireStatus.REZERVE, label: 'Rezerve' },

];

export enum ProjeSinifi {
    TICARI = 'ticari',
    KONUT = 'konut',
    TICARI_KONUT = 'ticari_konut',
    VILLA = 'villa',
}

export const ProjeSinifiChoices = [

    { value: ProjeSinifi.TICARI, label: 'Ticari' },
    { value: ProjeSinifi.KONUT, label: 'Konut' },
    { value: ProjeSinifi.TICARI_KONUT, label: 'Ticari + Konut' },
    { value: ProjeSinifi.VILLA, label: 'Villa' },

];
