<nb-card style="max-width: 600px;" class="modalType animated fadeInRight" size="giant">
    <nb-card-header class="d-flex align-items-center justify-content-between">
        {{firma?.name}} Personel Ekle

        <div>

            <button nbButton (click)="close()" size="small" class="m-close"
                style="background: none !important; color: black !important;  font-size: 18px;">
                X
            </button>
        </div>

    </nb-card-header>

    <nb-card-body [formGroup]="form"  [nbSpinner]="isLoading" >
        <!-- <div class="text-right mb-2">
            <button class="excel_button" nbButton size="small" (click)="excelImport()">Excelden İçeri Aktar</button>
        </div> -->
        <div class="row">

            <!--İsim-->
            <div class="col-md-6">
                <div class="form-group mb-1">
                    <label class="label form-label"> {{ 'PUANTAJ.ISIM' | translate }}</label>
                    <input nbInput fullWidth [placeholder]="'PUANTAJ.ISIM' | translate " formControlName="first_name"
                        fieldSize="medium" apasInvalid>
                </div>
            </div>
            <!--// İsim-->

            <!--Soyisim-->
            <div class="col-md-6">
                <div class="form-group mb-1">
                    <label class="label form-label">{{ 'PUANTAJ.SOYISIM' | translate }}</label>
                    <input nbInput fullWidth [placeholder]=" 'PUANTAJ.SOYISIM' | translate " formControlName="last_name"
                        fieldSize="medium" apasInvalid>
                </div>
            </div>
            <!--// Soyisim-->
            <!--Tc Kimlik-->
            <div class="col-md-12">
                <div class="form-group mb-1">
                    <label class="label form-label">{{ 'PUANTAJ.KIMLIKNO' | translate }}</label>
                    <input nbInput fullWidth [placeholder]=" 'PUANTAJ.KIMLIKNO' | translate" formControlName="kimlik_no"
                        type="number" min="0" fieldSize="medium" apasInvalid>
                </div>
            </div>
            <!--// Tc Kimlik-->
<!-- 
            <div class="col-md-12">
                <div class="form-group mb-1">
                    <label class="label form-label">{{ 'PUANTAJ.MESLEK' | translate }}</label>
                    <job-select formControlName="job_id" [addTag]="true" apasInvalid class="d-block"></job-select>
                </div>
            </div> -->

            <div class="col-md-12">
                <label class="label form-label">{{ 'PUANTAJ.TELEFON' | translate }}</label>
                <apas-telefon-input fullWidth formControlName="phone" [labelShow]="false" apasInvalid class="d-block"></apas-telefon-input>
            </div>
        </div>

        <div class="text-center mt-3">
            <button nbButton status="primary" (click)="postForm()">Kaydet</button>
        </div>

    </nb-card-body>

</nb-card>