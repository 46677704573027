import { createReducer, on } from '@ngrx/store';
import { UserSettingSet } from './user.settings.action';
import * as UserSettingAction from './user.settings.action'
interface UserSettingState {
    MuhasebeHesaplarCard: boolean;
}
const defaultUserSettingsState: UserSettingState = {
    MuhasebeHesaplarCard: false
};



const userSettingReducer = createReducer(
    defaultUserSettingsState,
    on(UserSettingAction.UserSettingSet, (state, payload:any) => {
         return { ...state, ...payload.userSetting};
    }),

);

function UserSettingsReducer(action, state) {
    return userSettingReducer(action, state);
}

export {
    UserSettingState,
    defaultUserSettingsState,
    UserSettingsReducer,
};
