import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { MusteriModel } from './musteri.model';
import { StickyDirection } from '@angular/cdk/table';

@Injectable()
export class MusteriService {
    private readonly API_URL = `${environment.backendurl}api/musteri/`;
    private readonly API_GROUP_URL = `${environment.backendurl}api/musteri/group/`;

    constructor(
        private httpClient: HttpClient,
    ) { }

    getMusteriList(formData: any): Observable<MusteriModel[]> {
        const url: string = `${this.API_URL}list/`;
        return this.httpClient.post<MusteriModel[]>(url, formData);
    }

    getMusteriDetail(id: number) {
        const url: string = `${this.API_URL}detail/${id}/`;
        return this.httpClient.get(url);
    }

    addMusteri(formData: any): Observable<MusteriModel[]> {
        const url: string = `${this.API_URL}add/`;
        return this.httpClient.post<MusteriModel[]>(url, formData);
    }

    updateMusteri(identifier: string, formData: any): Observable<MusteriModel[]> {
        const url: string = `${this.API_URL}update/${identifier}/`;
        return this.httpClient.patch<MusteriModel[]>(url, formData);
    }


    getGroupList() {
        const url: string = `${this.API_GROUP_URL}list/`;
        return this.httpClient.get(url);
    }

    addGroup(data) {
        const url: string = `${this.API_GROUP_URL}add/`;
        return this.httpClient.post(url, data);
    }

    removeMusteriFromGroup(instanceId) {
        const url: string = `${this.API_GROUP_URL}remove/${instanceId}/`;
        return this.httpClient.delete(url);
    }

    updateGroup(groupId: number, data) {
        const url: string = `${this.API_GROUP_URL}update/${groupId}/`;
        return this.httpClient.patch(url, data);
    }

    addMusteriToGroup(groupId: number, musteriList: number[]) {
        const url: string = `${this.API_GROUP_URL}addtogroup/${groupId}/`;
        return this.httpClient.post(url, musteriList);

    }

    deleteGroup(groupId) {
        const url: string = `${this.API_GROUP_URL}delete/${groupId}/`;
        return this.httpClient.delete(url);
    }
}
