import { ActionReducer, combineReducers, compose, createFeatureSelector } from '@ngrx/store';
import { CrmReducer, CrmState, defaultCrmStates } from '../../pages/crm/crm-store/crm-state';


const moduleReducers = {
        Crm: CrmReducer,
};


const defaultModuleStates = {
        Crm: defaultCrmStates,


};

interface ModuleState {
        Crm: CrmState;
}

const moduleReducer: ActionReducer<ModuleState> = combineReducers(moduleReducers);

function ModuleReducer(state: any, action: any) {

        return moduleReducer(state, action);
}

const getModuleSelector = createFeatureSelector<ModuleState>('module');

export {
        getModuleSelector,
        ModuleReducer,
        moduleReducer,
        defaultModuleStates,
        ModuleState,
};
