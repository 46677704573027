import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Observable, of } from 'rxjs';
import { responseKodlari, tarih_format, telefonKodu } from '../../../pages/constants';
import { removeAllNull } from '../../../pages/global-functions';
import { NbDialogRef } from '@nebular/theme';
import { FullFormBuild } from '../../../pages/formInterfaces';

@Component({
    selector: 'personel-ekle-firma',
    templateUrl: './personel-ekle-firma.component.html',
    styleUrls: ['./personel-ekle-firma.component.css'],
    providers: [OrganizasyonService],
    encapsulation: ViewEncapsulation.None,
})
export class PersonelEkleFirmaComponent implements OnInit {
    isSubmiting: boolean = false;
    tarih_format = tarih_format;
    personelForm: FormGroup;

    amIOwner: boolean = false;

    telefon_kodu = telefonKodu;
    ulkem: FormControl = new FormControl(telefonKodu[0]);

    options: any[];
    filteredOptions$: Observable<any[]>;
    show_other_info: boolean = false;

    constructor(
        private fb: FormBuilder,
        private organizasyonService: OrganizasyonService,
        private ref: NbDialogRef<PersonelEkleFirmaComponent>,
        protected cd: ChangeDetectorRef,
    ) {
        this.amIOwner = JSON.parse(localStorage.getItem('org')).is_owner;
    }


    ngOnInit() {
        this.initForm();
        this.ulkem.updateValueAndValidity();
    }

    initForm() {
        this.personelForm = this.fb.group({
            apas_id: [],
            kimlik_no: [null, Validators.maxLength(13)],
            phone: [null, Validators.required],
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            job_id: [],
        });
        this.personelForm.get('last_name').valueChanges.subscribe(val => {
            this.personelForm.get('apas_id').setValue(null, { emitEvent: false });
            this.searchPerson(val);
        });

    }

    addOrRemoveValidators() {
        
        if (this.personelForm.get('apas_id').value) {
            this.show_other_info = false;
            this.personelForm.get('kimlik_no').disable();
            this.personelForm.get('phone').disable();

        } else {
            this.show_other_info = true;
            this.personelForm.get('kimlik_no').enable();
            this.personelForm.get('phone').enable();
        }
        this.personelForm.updateValueAndValidity();
    }

    personelKaydet() {
        if (!this.personelForm.valid) {
            this.personelForm.markAllAsTouched();
            return;
        }
        const personelFormData = removeAllNull(this.personelForm.value);
        this.isSubmiting = true;
        this.organizasyonService.newPersonelAdd(personelFormData).subscribe((data) => {
            this.isSubmiting = false;
            if (data) {
                Swal.fire({
                    icon: 'success',
                    title: 'Kayıt Başarılı!',
                    showConfirmButton: false,
                    timer: 2000,
                });
                this.personelForm.reset();
                this.close(data);
            }
        }, err => {
            this.isSubmiting = false;
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
        });
    }

    searchPerson(val) {
        const name = `${this.personelForm.get('first_name').value ? this.personelForm.get('first_name').value : ''} ${this.personelForm.get('last_name').value ? this.personelForm.get('last_name').value : ''}`;
        this.organizasyonService.searchPerson({ name: name }).subscribe(resp => {
            this.options = resp;
            this.filteredOptions$ = of(this.options);
        }, (err) => {

        });

    }

    onSelectionChange(event) {
            this.personelForm.get('first_name').setValue(event.first_name);
            this.personelForm.get('last_name').setValue(event.last_name, { emitEvent: false });
            this.personelForm.get('apas_id').setValue(event.apas_id);
            this.personelForm.get('job_id').setValue(event.job_id?.id);
        this.addOrRemoveValidators();
    }

    close(resp?) {
        this.ref.close(resp);
    }
}
