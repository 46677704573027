import { createAction, props } from '@ngrx/store';

export const loadData = createAction('[Load Projeler List]', props<{ filter?: any[] }>());
export const loadDataSuccess = createAction('[Load Success Projeler List]', props<{ data: any[], isEnd?: boolean }>());
export const loadDataFail = createAction('[Load Proje Fail]', props<{ error: string, isEnd?: boolean }>());

export const resetData = createAction('[Filter Data Projeler]');

export const selectProje = createAction('[Selected Proje]', props<{ selectedId: number }>());

export const addNewProje = createAction('[Add New Proje]', props<{ data: any }>());
export const addNewProjeSuccess = createAction('[Add New Proje Success]', props<{ data: any }>());
export const addNewProjeFail = createAction('[Add New Proje Fail]', props<{ error: any }>());

export const getProjeDetail = createAction('[Get] CRM Proje Detay', props<{projeId: number}>());
export const setProjeDetail = createAction('[Set] CRM Proje Detay', props<{projeId: number, data: any }>());
