import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ProjeItem } from '../../crm-model/projeler-model';
import * as projeActions from './projeler.actions';

interface ProjelerDefaultState extends EntityState<ProjeItem> {
    selectedProjeid: number | null;
    end: boolean;
    addLoading: boolean;
    addSuccess: boolean;
    loading: boolean;
    loaded: boolean;
    error: String;
}

const defaultProjelerState: ProjelerDefaultState = {
    ids: [],
    entities: {},
    selectedProjeid: null,
    end: false,
    addLoading: false,
    addSuccess: null,
    loading: false,
    loaded: false,
    error: '',
};

const projelerAdapter: EntityAdapter<ProjeItem> = createEntityAdapter<ProjeItem>();
const initialState = projelerAdapter.getInitialState(defaultProjelerState);

const projelerReducer = createReducer(
    initialState,

    on(projeActions.loadData, (state, _props) => {
        return { ...state, loading: true };
    }),

    on(projeActions.loadDataSuccess, (state, props: any) => {
        return projelerAdapter.upsertMany(props.data.results, {
            ...state,
            loading: false,
            loaded: true,
            end: props.isEnd,
        });
    }),

    on(projeActions.loadDataFail, (state, _props) => {
        return { ...state, error: _props.error, loading: false };
    }),

    on(projeActions.resetData, () => {
        return { ...defaultProjelerState, ...{ loading: true, end: false } };
    }),

    on(projeActions.selectProje, (state, props) => {
        return { ...state, selectedProjeid: props.selectedId };
    }),

    on(projeActions.addNewProje, (state) => {
        return { ...state, addLoading: true };
    }),

    on(projeActions.addNewProjeSuccess, (state, props: any) => {
        return { ...state, ...projelerAdapter.upsertOne(props.data, state), addLoading: false, addSuccess: true };
    }),

    on(projeActions.addNewProjeFail, (state, props) => {
        return { ...state, error: props.error, addLoading: false, addSuccess: false };
    }),

    on(projeActions.getProjeDetail, (state, props) => {
        return { ...state, ...{ loading: true } };
    }),

    on(projeActions.setProjeDetail, (state, props) => {
        let proje = state.entities[props.projeId];
        proje = {...proje, ...props.data};
        return { ...state, ...projelerAdapter.upsertOne(proje, state), loading: false, addSuccess: true };

    }),
);


function ProjelerReducers(action, state) {
    return projelerReducer(action, state);
}


export {
    ProjelerDefaultState,
    defaultProjelerState,
    ProjelerReducers,
    projelerAdapter,
};
